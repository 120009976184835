import React, { useEffect, useState } from "react";
import "../InforAccount.scss";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import DetailInfor from "../detailInfor/DetailInfor";
import ChangePass from "../changePass/ChangePass";
import { observer } from "mobx-react";
import { inforStore } from "../InforAccountStore";
import StorageService from "../../../../common/service/StorageService";
import { getCookie, setCookie, urlImage } from "../../../../common/utils/Utils";
import Constants from "../../../../common/constants/Constants";
import { loginStore } from "../../../auth/login/LoginStore";
import SelectAccountInfor from "./components/SelectAccountInfor";
import { useTranslation } from "react-i18next";

const InforAccountPage = () => {
  const { t } = useTranslation(["translation"], {
    keyPrefix: "profile",
  });

  const [activeIndex, setActiveIndex] = useState(0);

  let redirectCheckDomain = process.env.REACT_APP_REDIRECT_CHECK_DOMAIN;


  const [searchParams] = useSearchParams();
  const location = useLocation();
  const handleLinkClick = (index: React.SetStateAction<number>) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    if (activeIndex === 0) {
      let dataTokenUsername = inforStore.checkTokenByUsername(
        searchParams.get("username")
      );
      StorageService.setToken(dataTokenUsername["token"]);
      localStorage.setItem("dataSso", JSON.stringify(dataTokenUsername));
      setCookie(
        "dataSso",
        JSON.stringify(dataTokenUsername),
        Constants.TOKEN_EXPIRE_DAYS
      );
      inforStore.getProfiles().then(() => {});
    }
    if (localStorage.getItem("listAccount")) {
      let listAcc: any = localStorage.getItem("listAccount");
      loginStore.listAccount = JSON.parse(listAcc);
      let indexUsername = Number(
        loginStore.listAccount
          .map((item: any) => item.username)
          .indexOf(searchParams.get("username"))
      );
      inforStore.currentProfile = loginStore.listAccount[indexUsername];
      inforStore.diffProfile = loginStore.listAccount.filter(
        (item: any) => item.username !== searchParams.get("username")
      );
    }
  }, [activeIndex, searchParams]);
  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="header-infor">
        <div className="logo">
              <img src="/assets/images/Logo_OMCHECK.svg" style={{width: '150px'}} alt="" />
        </div>
        <div>
          <div className="action">
            <div className="toogle">
              <img
                className="button-reset-icon toggle"
                src="/assets/images/MN.svg"
                alt=""
              />
            </div>
            <div className="menu">
              <ul>
               <li>
                  <a
                      href={`${redirectCheckDomain}/?token=${getCookie("token")}`}
                      target="_blank"
                      rel="noreferrer"
                  >
                    <img
                        className="button-reset-icon toggle mx-2"
                        style={{width: '14px', height: '14px'}}
                        src="/assets/images/selectOmcheck.svg"
                        alt=""
                    />
                    OmCheck
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="select_account_container">
            <div className="toogle">
              {inforStore.dataProfile.avatar ? (
                <img
                  src={
                    urlImage(inforStore.dataProfile.avatar) as unknown as string
                  }
                  alt=""
                  className="avatar"
                />
              ) : (
                <span className="text-white">
                  {inforStore.currentProfile?.name.slice(0, 1)}
                </span>
              )}
            </div>
            <div className="menu">
              {
                <SelectAccountInfor
                  pathName={new URLSearchParams(location.search)
                    .get("redirectTo")
                    ?.toString()}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex w-100 body-infor">
        <div className="sidebar-container">
          <div className="sidebar">
            <ul>
              <li>
                <Link
                  className={activeIndex === 0 ? "active" : ""}
                  onClick={() => handleLinkClick(0)}
                  to={location.pathname + location.search}
                >
                  <img
                    className="button-reset-icon toggle mx-2"
                    src={`/assets/images/${
                      activeIndex === 0 ? "homeBlue.svg" : "homeGrey.svg"
                    }`}
                    alt=""
                  />
                  <span className="mx-3">{t("homePage")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className={activeIndex === 1 ? "active" : ""}
                  onClick={() => handleLinkClick(1)}
                  to={location.pathname + location.search}
                >
                  <img
                    className="button-reset-icon toggle mx-2"
                    src={`/assets/images/${
                      activeIndex === 1 ? "keyBlue.svg" : "keyGrey.svg"
                    }`}
                    alt=""
                  />
                  <span className="mx-3">{t("password")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
          {activeIndex === 0 ? <DetailInfor /> : <ChangePass />}
      </div>
    </div>
  );
};

export default observer(InforAccountPage);
