import React, {useEffect} from 'react';
import './App.css';
import {observer} from "mobx-react";
import 'react-toastify/dist/ReactToastify.css';
import Redirect from "./pages/router/Redirect";
import {profileStore} from "./pages/profile/profileStore";
import {useLocation} from "react-router-dom";

declare global {
    interface Window {
        sendMobile: (e: any) => void
    }
}

const App = () => {

    const location = useLocation();
    useEffect(() => {
        profileStore.checkValidateToken()
    }, [])

    return (
            <div
                className={`ommani-sso ${location.pathname === '/profile' || location.pathname === '/profile/change-pass' ? "ommani-infor-acount" : ""}`}>
                <Redirect/>
            </div>
    );

}

export default observer(App);
