/* eslint-disable array-callback-return */
import {observable} from 'mobx';
import {toastUtil} from "../../../common/utils/ToastUtil";
import StorageService from "../../../common/service/StorageService";
import {accountService} from "../AccountService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {profileStore} from "../../profile/profileStore";
import {getExpAcount, parseJwt, setCookie} from "../../../common/utils/Utils";
import Constants from "../../../common/constants/Constants";



class LoginStore {
    @observable public username = "";
    @observable public password = "";
    @observable public language = "";
    @observable public path = "";
    @observable public showPass: boolean = true;
    @observable public showForm: boolean = false;
    @observable public isCms: any = "";
    @observable public service: any = "";
    @observable public redirectTo: any = "";
    @observable public listAccount: any[] = [];
    @observable public isLoading: boolean = false;
    @observable public step: number = 1;

    @observable public formError: {
        name: string,
        pass: string,
        message: string,
    } = {
        name: '',
        pass: '',
        message: '',
    }

    clearForm(){
        loginStore.username = ""
        loginStore.password = ""
    }

    setUserNameValue(e: any) {
        loginStore.username = e.target.value;
        loginStore.formError.name = ''
    }

    setPassValue(e: any) {
        loginStore.password = e.currentTarget.value;
        loginStore.formError.pass = ""
    }





    async login() {

        StorageService.removeToken();
        let regexPhone: RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

        let username: string = loginStore.username;
        let password: string = loginStore.password;

        if (!username) {
            loginStore.formError.name = 'Số điện thoại không được để trống';
            return false;
        }
        if (!regexPhone.test(username)) {
            loginStore.formError.name = 'Số điện thoại chưa đúng định dạng!';
            return false;
        }
        if (password.length > 24 || password.length < 6) {
            loginStore.formError.pass = "Mật khẩu phải từ 6 đến 24 ký tự"
            return false;
        }

        loginStore.showForm = true
        loginStore.isLoading = true;
        const data: any = await accountService.login(username, password, loginStore.service ?? 'farm');
        loginStore.isLoading = false;

        if (data.status === HttpStatusCode.OK) {
            if(loginStore.isCms && !data.body.claims.isAdmin){
                toastUtil.warning('Tài khoản này không phải admin!');
                return
            }
            let token: any = data.body.token;
            let avatar: any = data.body?.claims?.avatar ?? '';
            StorageService.setToken(token);
            let refreshToken: string = data.body.refreshToken;
            localStorage.setItem('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}));
            setCookie('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}), Constants.TOKEN_EXPIRE_DAYS);
            StorageService.setRefreshToken(refreshToken);
            toastUtil.success('Đăng nhập thành công');
            loginStore.path = `${loginStore.redirectTo ?? 'https://demo.omfarm.com.vn/'}${loginStore.isCms ? '/cms/users' : ''}?token=${token}${loginStore.service !== 'life' ? `&refreshToken=${refreshToken}` : ''}`
            parseJwt(token)

            if(loginStore.redirectTo){
                window.location.href = loginStore.path
            }
            else {
                window.location.href = `https://accounts.omcheck.com/profile?username=${loginStore.username}`
            }
            const name = profileStore.dataInfo?.name ?? 'Omfarm';
            loginStore.listAccount && loginStore.listAccount.map((item: any, i) => {
                if(item.username === username){
                    loginStore.username = item.username
                    loginStore.listAccount.splice(i, 1)
                }
            })
            loginStore.listAccount.unshift({
                name: name,
                username: username,
                avatar: avatar,
                token: token,
                refreshToken: refreshToken,
                exp : getExpAcount(token)
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))

            const response = {
                data : {
                    event: "ON_LOGIN",
                    ssoData: {
                        token: `${token}`,
                        refreshToken: `${refreshToken}`
                    }
                }
            };

            window.sendMobile(JSON.stringify(response));

            loginStore.showForm = true
            loginStore.clearForm()
        } else {
            toastUtil.error(data.body.message, 2)
        }

    }

    async enterLogin(e: any) {
        if (e.key === 'Enter') {
            await loginStore.login()
        }
    }

    async logout(item: any){
        StorageService.setRefreshToken(item.refreshToken)
        loginStore.listAccount.map((val, i) => {
            if(val.username === item.username){
                loginStore.listAccount.splice(i, 1)
            }
        })
        localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
        const result = await accountService.logOut(item.token)
        if(result.status === 200){

        }else {
            loginStore.showForm = true
            loginStore.username = item.username
        }
    }
}


export const loginStore = new LoginStore();
