import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { loginStore } from "./LoginStore";
import InputPassword from "../../../common/component/InputPassword";
import { Link, useSearchParams } from "react-router-dom";
import SelectAccount from "./SelectAccount";
import { useTranslation } from "react-i18next";

const LoginPage = observer(() => {
  let searchParams: URLSearchParams;
  [searchParams] = useSearchParams();
  let url = window.location.href;

  useEffect(() => {
    if (localStorage.getItem("listAccount")) {
      loginStore.showForm = false;
      let listAcc: any = localStorage.getItem("listAccount");
      loginStore.listAccount = JSON.parse(listAcc);
    }
    if (searchParams.get("form")) {
      loginStore.showForm = true;
      loginStore.username =
        searchParams.get("username") === "null"
          ? ""
          : (searchParams.get("username") as any);
    }
    loginStore.isCms = searchParams.get("cms");
    loginStore.service = searchParams.get("service");
    loginStore.redirectTo = searchParams.get("redirectTo");
  }, [searchParams, url]);
  const { t } = useTranslation(["translation"], {
    keyPrefix: "login",
  });
  return (
    <div className="login_main">
      {loginStore.listAccount &&
        loginStore.listAccount.length > 0 &&
        loginStore.showForm && (
          <div className="button_back_list_account_container">
            <button
              onClick={() => (loginStore.showForm = false)}
              className="button_back_list_account"
            >
              <img
                style={{ marginRight: "6px" }}
                src="/assets/images/arrow-big-left.svg"
                alt=""
              />
              <span style={{ color: "#000" }}>{t("accountList")}</span>
            </button>
          </div>
        )}
      <div className="login_main_container">
        <div className="form_login bg-white">
          <div className="logo_login d-flex justify-content-center">
                <img src="/assets/images/omcheck-fill.svg" alt="" />
          </div>
          {loginStore.listAccount &&
          loginStore.listAccount.length > 0 &&
          !loginStore.showForm ? (
            <h2 className="text-center mb-3">{t("chooseAccount")}</h2>
          ) : (
            <>
              <h2 className="text-center d-none d-lg-block">{t("title")}</h2>
              <p className="text-center d-none d-lg-block">
                {t("subTitle")}{" "}
                OmCheck
              </p>
            </>
          )}
          {loginStore.listAccount &&
          loginStore.listAccount.length > 0 &&
          !loginStore.showForm ? (
            <SelectAccount />
          ) : (
            <form autoComplete="off">
              <div className="form-group">
                <label>
                  {t("phoneNumber")} <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    value={loginStore.username}
                    onChange={(e: any) => {
                      loginStore.setUserNameValue(e);
                    }}
                    className={`form-control form-control-lg ${
                      loginStore.formError.name ? "border_error" : ""
                    }`}
                    placeholder={t("enterNumberPhone")}
                  />
                </div>
                <p className="error">
                  {loginStore.formError && loginStore.formError.name}
                </p>

              </div>
              <div className="form-group">
                <label>
                  {t("password")}
                  <span className="text-danger">*</span>
                </label>
                <InputPassword
                  value={loginStore.password}
                  onChange={loginStore.setPassValue}
                  isError={loginStore.formError.pass}
                  onKeyDown={loginStore.enterLogin}
                  placeholder={t("enterPassword")}
                />
              </div>

              <div className="footer_action">
                <Link
                  to={`/register${
                    searchParams.get("redirectTo")
                      ? `?redirectTo=${searchParams.get("redirectTo")}`
                      : ""
                  }${
                    searchParams.get("service")
                      ? `&service=${searchParams.get("service")}`
                      : ""
                  }${
                    searchParams.get("name")
                      ? `&service=${searchParams.get("name")}`
                      : ""
                  }${
                    searchParams.get("lng")
                      ? `?lng=${searchParams.get("lng")}`
                      : ""
                  }`}
                  className="btn bt-register"
                >
                  {t("signUp")}
                </Link>
                <button
                  type="button"
                  className="btn btn-save"
                  onClick={() => loginStore.login()}
                  disabled={loginStore.isLoading}
                >
                  {loginStore.isLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    `${t("signIn")}`
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
});

export default LoginPage;
