/* eslint-disable array-callback-return */
import {accountService} from "../auth/AccountService";
import {observable} from "mobx";
import {loginStore} from "../auth/login/LoginStore";
import {parseJwt, setCookie} from "../../common/utils/Utils";
import StorageService from "../../common/service/StorageService";
import Constants from "../../common/constants/Constants";
import {toastUtil} from "../../common/utils/ToastUtil";


class ProfileStore {

    @observable dataInfo: any = {
        authenticate: false,
        exp: "",
        farmId: "",
        facilityId: "",
        roleName: '',
        name: '',
        farmOwner: false,
        iat: "",
        roles: "",
        userId: ""
    }

    async getToken(val: any){
        StorageService.setRefreshToken(val.refreshToken)
        StorageService.setToken(val.token)
        const result = await accountService.getToken(val.token)
        if(result.status === 200){
            let token: string = result.body.token;
            parseJwt(token)
            loginStore.listAccount && loginStore.listAccount.map((item) => {
                if(item.username === val.username){
                    item.token = token
                    localStorage.setItem('dataSso', JSON.stringify({token: token, refreshToken: val.refreshToken}));
                    setCookie('dataSso', JSON.stringify({token: token, refreshToken: val.refreshToken}), Constants.TOKEN_EXPIRE_DAYS);
                    window.location.href = loginStore.redirectTo ? `${loginStore.redirectTo}${loginStore.isCms ? '/cms/users' : ''}?token=${token}${loginStore.service !== 'life' ? `&refreshToken=${val.refreshToken}` : ''}`
                        : `https://accounts.omcheck.com/profile?username=${val.username}`

                    const response = {
                        data : {
                            event: "ON_LOGIN",
                            ssoData: {
                                token: `${token}`,
                                refreshToken: `${val.refreshToken}`
                            }
                        }       
                    };

                    window.sendMobile(JSON.stringify(response));
                }
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
        }else {
            loginStore.showForm = true
            loginStore.username = val.username
            toastUtil.error(result.body.message ?? 'Đã có lỗi xảy ra', 2)
            loginStore.listAccount && loginStore.listAccount.map((item, i) => {
                if(item.username === val.username){
                    loginStore.listAccount.splice(i, 1)
                }
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
        }
    }

    async checkValidateToken(){
        const result = await accountService.checkToken()
        if(result.status === 200){
            profileStore.dataInfo = result.body
        }
    }


}

export const profileStore = new ProfileStore()