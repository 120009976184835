import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {accountService} from "../AccountService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {loginStore} from "../login/LoginStore";
import StorageService from "../../../common/service/StorageService";
import {getExpAcount} from "../../../common/utils/Utils";

interface IErrors {
    name: string,
    username: string,
    email: string
    password: string,
    confirmPass: string,
    acceptTerms: string
}

interface IDataRequestSignUp {
    name: string,
    username: string,
    email?: string
    password: string,
    confirmPass: string,
    birthDay?: number | string,
    gender?: number | string
}


class SignUpStore {
    @observable public step: any = 1
    @observable public showPass: boolean = false
    @observable public acceptTerms: boolean = false
    @observable public text_error: any = ''
    @observable public errors: IErrors = {
        name: "",
        username: "",
        email: "",
        password: "",
        confirmPass: '',
        acceptTerms: ''
    }
    @observable public isLoading: boolean = false
    @observable public dataRequest: IDataRequestSignUp = {
        name: "",
        username: "",
        password: "",
        confirmPass: "",
        birthDay: "",
        gender: "",
    }

    clearForm() {
        signUpStore.errors = {
            name: "",
            username: "",
            email: "",
            password: "",
            confirmPass: '',
            acceptTerms: ''
        }
        signUpStore.dataRequest = {
            name: "",
            username: "",
            email: "",
            password: "",
            confirmPass: '',
            birthDay: "",
            gender: "",
        }
    }
    validateStepOne() {
        let regexPhone: RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        let {name, username, password, confirmPass} = signUpStore.dataRequest

        if (!name.trim()) {
            signUpStore.errors.name = 'Họ và tên không được để trống!'
            return
        } else signUpStore.errors.name = ''

        if (!username) {
            signUpStore.errors.username = 'Số điện thoại không được để trống!'
            return
        }

        if (!regexPhone.test(username)) {
            signUpStore.errors.username = "Số điện thoại chưa đúng định dạng!"
            return
        } else signUpStore.errors.username = ''

        if (!password.trim()) {
            signUpStore.errors.password = 'Mật khẩu không được để trống!'
            return
        }

        if (password.trim().length < 6 || password.trim().length > 24) {
            signUpStore.errors.password = 'Mật khẩu tối thiểu từ 6 đến 24 ký tự!'
            return
        } else signUpStore.errors.password = ''

        if (!confirmPass.trim()) {
            signUpStore.errors.confirmPass = 'Mật khẩu không được để trống!'
            return
        }

        if (password !== confirmPass) {
            signUpStore.errors.confirmPass = 'Mật khẩu không trùng khớp!'
            return
        } else signUpStore.errors.confirmPass = ''
        signUpStore.step = 2

    }

    async signUp() {
        if (!signUpStore.acceptTerms) {
            signUpStore.errors.acceptTerms = 'Vui lòng đồng ý với điều khoản của chúng tôi !'
            return
        } else signUpStore.errors.acceptTerms = ''
        StorageService.removeToken();

        let {name, username, password, email, birthDay, gender} = signUpStore.dataRequest

        let data = {
            name: name.trim(),
            username: username,
            password: password.trim(),
            email: email?.trim(),
            service: loginStore.service,
            birthDay: birthDay !== "" ? birthDay : null,
            gender: gender !== "" ? gender : null,
        }

        loginStore.username = username
        signUpStore.isLoading = true
        const result = await accountService.register(data)
        signUpStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            const response = {
                data : {
                    event: "ON_SIGNUP",
                    ssoData: {
                        token: result.body.token,
                        refreshToken: result.body.refreshToken
                    }
                }
            };
            window.sendMobile(JSON.stringify(response));
            loginStore.path = loginStore.redirectTo ? `${loginStore.redirectTo}?token=${result.body.token}${loginStore.service !== 'life' ? `&refreshToken=${result.body.refreshToken}` : ''}` : `/profile?username=${loginStore.username}`
            window.location.href = loginStore.path
            toastUtil.success("Đăng ký tài khoản thành công", 1)

            loginStore.listAccount.unshift({
                name: name,
                username: username,
                token: result.body.token,
                refreshToken: result.body.refreshToken,
                exp : getExpAcount(result.body.token)
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
        } else {
           toastUtil.error(result.body.message, 2)
        }
    }
    async changePass(e: any) {
        signUpStore.dataRequest.password = e.currentTarget.value
        if(signUpStore.dataRequest.password.length < 6 || signUpStore.dataRequest.password.length > 24){
            signUpStore.errors.password = 'Mật khẩu tối thiểu từ 6 đến 24 ký tự!'
        }else {
            signUpStore.errors.password = ""
        }
    }

    async changeConfirmPass(e: any) {
        signUpStore.dataRequest.confirmPass = e.currentTarget.value
        if (signUpStore.dataRequest.password !== signUpStore.dataRequest.confirmPass) {
            signUpStore.errors.confirmPass = 'Mật khẩu không trùng khớp!'
            return
        } else signUpStore.errors.confirmPass = ''
    }


}

export const signUpStore = new SignUpStore()