import {postRequest, IApiResponse, getRequest, deleteRequest, putRequest} from "../../common/helpers/RequestHelper";
import {loginStore} from "./login/LoginStore";

class AccountService {
    public login(username: string, password: string, service: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/login`, {username, password, service});
    }

    public register(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/register`, data);
    }

    public logOut(token: any): Promise<IApiResponse> {
        return deleteRequest('/sso/v1/auth/logout', {},  token);
    }

    public logOutAll(token: any): Promise<IApiResponse> {
        return deleteRequest('/sso/v1/auth/logout?logoutAll=true', {}, token);
    }

    public checkToken(): Promise<IApiResponse> {
        return getRequest(`/sso/v1/auth/valid-token`)
    }

   public getToken(token: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/gettoken?service=${loginStore.service ?? 'farm'}`, {}, token)
    }

    public getProfile(): Promise<IApiResponse> {
        return getRequest(`/sso/v1/user`)
    }
    
    public updateProfile(data: any, token: any): Promise<IApiResponse> {
        return putRequest(`/sso/v1/user`, data, false , token)
    }

    public changePass(data: any, token: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/user/change_password`, data, token)
    }
}

export const accountService = new AccountService();
