import humps from 'humps';
import axios, {Method} from "axios";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import {loginStore} from "../../pages/auth/login/LoginStore";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
}


let API_DOMAIN = process.env.REACT_APP_API_URL_DOMAIN


export async function getRequest(path: string): Promise<IApiResponse> {

    var newHeaders: any = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken()
    }


    return await axios.get(API_DOMAIN + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response?.status,
                    body: humps.camelizeKeys(response?.data),
                };
                return apiResponse;
            },
            (error) => {
                if (error.response && error.response?.status === HttpStatusCode.UNAUTHORIZED) {
                    toastUtil.error('Phiên đăng nhập hết hạn', 2);
                    loginStore.showForm = true
                    window.location.href = `https://accounts.omcheck.com?redirectTo=${new URLSearchParams(window.location.search).get('redirectTo')}&username=${new URLSearchParams(window.location.search).get('username')}&service=${new URLSearchParams(window.location.search).get('service')}&form=true`
                    StorageService.removeToken();
                } else if (error.response && error.response?.status === HttpStatusCode.FORBIDDEN) {
                    toastUtil.error('Tài khoản của bạn không có quyền', 2);
                }
                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response?.data?.errorCode,
                        message: error.response?.data?.message
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };

                return apiResponse;
            },
        )
}


export async function postRequest(path: string, params: object, token?: any): Promise<IApiResponse> {
    return apiCall(path, "POST", params, token);
}

export async function deleteRequest(path: string, params: object, token?: any): Promise<IApiResponse> {
    return apiCall(path, "DELETE", params, token);
}
export async function putRequest(path: string, params: object, methodPATCH?: any,  token?: any): Promise<IApiResponse> {
    return apiCall(path, methodPATCH ? 'PATCH' : "PUT", params, token);
}
export function apiCall(path: string, _method: Method = "POST", _params: object, token?: any): Promise<IApiResponse> {
    var newHeaders: any = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }

    if (StorageService.isTokenExits()) {
        newHeaders['Authorization'] = 'Bearer ' + StorageService.getToken()
    }


    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: API_DOMAIN + path
        })
            .then(function (response) {
                resolve({
                    status: response?.status,
                    body: humps.camelizeKeys(response?.data),
                });
            })
            .catch(function (error) {
                    if (error.response && ( error.response?.status === HttpStatusCode.UNAUTHORIZED)) {
                        if(_method !== "DELETE"){
                            toastUtil.error('Phiên đăng nhập hết hạn', 2);
                        }
                        loginStore.showForm = true
                        StorageService.removeToken();
                        StorageService.removeRefreshToken();
                    } else if (error.response && error.response?.status === HttpStatusCode.FORBIDDEN) {
                        toastUtil.error('Tài khoản của bạn không có quyền', 2);
                    }

                    let bodyError: IBodyError;
                    try {
                        if (error.response && error.response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                            bodyError = {
                                errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                                message: "Internal server error, please try again later"
                            }
                        } else {
                            bodyError = {
                                errorCode: error.response?.data?.errorCode,
                                message: error.response?.data?.message
                            }
                        }

                    } catch (e) {
                        bodyError = {
                            errorCode: HttpStatusCode.UNKNOW_ERROR,
                            message: "Unknow error, please try again later"
                        }
                    }

                    const apiResponse: IApiResponse = {
                        status: error.response?.status,
                        body: bodyError
                    };

                    resolve(apiResponse);
                }
            );

    });
}


