import React from "react";
import { observer } from "mobx-react";
import { inforStore } from "../../InforAccountStore";
import { getCookie, urlImage } from "../../../../../common/utils/Utils";
import { useTranslation } from "react-i18next";

export const randomColor = () => {
  const arrColors = [
    "#DB3500",
    "#F69320",
    "#BF5D02",
    "#792FD7",
    " #0054B7",
    "#008F42",
    "#DB3500",
    "#00C259",
    "#32A1DA",
  ];
  let index = Math.floor(Math.random() * arrColors.length);
  return arrColors[index];
};

const SelectAccountInfor = (pathName: any): JSX.Element => {
  const { t } = useTranslation(["translation"], {
    keyPrefix: "profile",
  });

  return (
    <div className="select_account_infor">
      <div className="list_account_infor">
        <div className="account_current_infor">
          <div className="d-flex align-items-center w-100 h-100">
            {inforStore.dataProfile.avatar ? (
              <div>
                <img
                  src={
                    urlImage(inforStore.dataProfile.avatar) as unknown as string
                  }
                  alt=""
                  className="avatar avatar-image"
                />
              </div>
            ) : (
              <div className="avatar">
                <span className="text-white">
                  {inforStore.currentProfile?.name.slice(0, 1)}
                </span>
              </div>
            )}
            <div className="info">
              <p>{inforStore.dataProfile?.name ?? ""}</p>
              <span>{inforStore.currentProfile?.username ?? ""}</span>
            </div>
          </div>
        </div>
        <div className="diff-account-container">
          {inforStore.diffProfile && inforStore.diffProfile.length > 0 ? (
            inforStore.diffProfile.map((item, i) => {
              return (
                <a
                  key={i}
                  href={
                    pathName.pathName
                      ? `${
                          process.env.REACT_APP_DOMAIN_SSO
                        }/profile?redirectTo=${pathName.pathName}&username=${
                          item.username
                        }&service=${new URLSearchParams(
                          window.location.search
                        ).get("service")}${
                          new URLSearchParams(window.location.search).get(
                            "lang"
                          )
                            ? `&lang=${new URLSearchParams(
                                window.location.search
                              ).get("lang")}`
                            : ""
                        }`
                      : `https://accounts.omcheck.com/profile?username=${
                          item.username
                        }${
                          new URLSearchParams(window.location.search).get(
                            "lang"
                          )
                            ? `&lang=${new URLSearchParams(
                                window.location.search
                              ).get("lang")}`
                            : ""
                        }`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="account_infor">
                    <div className="d-flex align-items-center w-100 h-100">
                      {item.avatar ? (
                        <div>
                          <img
                            src={urlImage(item.avatar) as unknown as string}
                            alt=""
                            className="avatar avatar-image"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: randomColor(),
                          }}
                          className="avatar"
                        >
                          <span className="text-white">
                            {item.name.slice(0, 1)}
                          </span>
                        </div>
                      )}
                      <div className="info">
                        <p>{item.name}</p>
                        <span>{item?.username}</span>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })
          ) : (
            <div
              style={{
                padding: "10px 20px",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              Không có tài khoản nào
            </div>
          )}
        </div>
        <div className="logout-account">
          <button
            style={{ border: "none", outline: "none" }}
            onClick={async () => {
              await inforStore.logOutAll(getCookie("token"));
            }}
          >
            <img
              className="button-reset-icon toggle mx-2"
              src="/assets/images/log-out.svg"
              alt=""
            />
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(SelectAccountInfor);
