import {Route, Routes} from "react-router-dom";
import React from "react";
import LoginPage from "../auth/login/LoginPage";
import SignUpPage from "../auth/signUp/SignUpPage";
import InforAccountPage from "../profile/infor/inforAccount/InforAccountPage";

export default function Redirect() {

    return (
        <Routes>
            <Route path={`/`} element={<LoginPage/>}/>
            <Route path={`/login`} element={<LoginPage/>}/>
            <Route path={`/register`} element={<SignUpPage/>}/>
            <Route path={`/profile`} element={<InforAccountPage/>}/>
        </Routes>
    )

}