import {observable} from "mobx";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {accountService} from "../../auth/AccountService";
import {toastUtil} from "../../../common/utils/ToastUtil";
import {getCookie} from "../../../common/utils/Utils";
import StorageService from "../../../common/service/StorageService";

interface IErrors {
    name: string,
    username: string,
    email: string
    acceptTerms: string,
    oldPassword: string,
    password: string,
    confirmPassword: string
}

interface IDataRequestInforAccount {
    name: string,
    username: string,
    email?: string
    password: string,
    confirmPass: string,
    birthday?: number | string,
    sex?: number | string
    acceptTerms: boolean,
}


class InforAccountStore {
    @observable public step: any = 1
    @observable public showPass: boolean = false
    @observable public text_error: any = ''
    @observable public errors: IErrors = {
        name: "",
        username: "",
        email: "",
        acceptTerms: '',
        oldPassword: '',
        password: '',
        confirmPassword: ''
    }
    @observable public isLoading: boolean = false
    @observable public isLoadingBt: boolean = false;
    @observable public changeAccount: boolean = false;
    @observable public currentProfile: {name: string, username: string, avatar: string} = {
        name: "",
        username: "",
        avatar: ""
    };
    @observable public diffProfile: {name: string, username: string, avatar: string}[] = [];
    @observable public dataRequest: IDataRequestInforAccount = {
        name: "",
        username: "",
        password: "",
        confirmPass: "",
        birthday: "",
        sex: "",
        acceptTerms: false
    }

    clearForm() {
        inforStore.errors = {
            name: "",
            username: "",
            email: "",
            oldPassword: "",
            password: "",
            confirmPassword: '',
            acceptTerms: ''
        }
        inforStore.dataRequest = {
            name: "",
            username: "",
            email: "",
            password: "",
            confirmPass: '',
            birthday: "",
            sex: "",
            acceptTerms: false
        }
    }

    @observable public dataProfile: any = {
        id: "",
        username: "",
        isAdmin: false,
        name: "",
        email: "",
        birthDay: "",
        gender: "",
        avatar: ""
    }
    @observable public userPassword: any = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    };
    async getProfiles() {
        const response = await accountService.getProfile();
        if (response.status === HttpStatusCode.OK) {
            inforStore.dataProfile = response.body;
        }
         else  {
            toastUtil.error(response.body.message, 2)
        }
    }
    checkTokenByUsername(username?: string | null) {
        let indexUsername = -1
        let listAccount = localStorage.getItem('listAccount')
        if(listAccount && username) {
            indexUsername = Number(JSON.parse(listAccount).map((item: any) => item.username).indexOf(username))
            return {
                token: JSON.parse(listAccount)[indexUsername]?.token,
                refreshToken: JSON.parse(listAccount)[indexUsername]?.refreshToken
            }
        }
        else return {}
    }

    async changeInfor() {
        let {name, email, birthDay, gender, avatar} = inforStore.dataProfile
        if (!name.trim()) {
            inforStore.errors.name = 'Họ và tên không được để trống!'
            return
        } else inforStore.errors.name = ''
        let data = {
            name: name.trim(),
            email: email?.trim(),
            birthDay: birthDay,
            gender: gender,
            avatar: avatar
        }
        inforStore.isLoadingBt = true
        const result = await accountService.updateProfile(data, getCookie('token'))
        inforStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success("Cập nhật thông tin tài khoản thành công", 1)
            const response = {
                data : {
                    event: "ON_CHANGE_PROFILE",
                    ssoData: true
                }
            };

            window.sendMobile(JSON.stringify(response));
        } else {
            toastUtil.error(result.body.message, 2)
        }
    }

    async changePassword() {
        let {oldPassword, password, confirmPass} = inforStore.userPassword

        if (!oldPassword) {
            inforStore.errors.oldPassword = "Mật khẩu hiện tại không được để trống!"
            return
        }

        if (oldPassword.length < 6 || oldPassword.length > 50) {
            inforStore.errors.oldPassword = "Mật khẩu phải từ 6 đến 24 ký tự!"
            return
        } else inforStore.errors.oldPassword = ""

        if (!password) {
            inforStore.errors.password = "Mật khẩu không được để trống!"
            return
        }

        if (password.length < 6 || password.length > 50) {
            inforStore.errors.password = "Mật khẩu phải từ 6 đến 24 ký tự!"
            return
        } else inforStore.errors.password = ""

        if (!confirmPass) {
            inforStore.errors.confirmPassword = "Mật khẩu xác nhận không được để trống!"
            return
        }

        if (confirmPass === password) {
            inforStore.errors.confirmPassword = ""
        } else {
            inforStore.errors.confirmPassword = "Mật khẩu và mật khẩu xác nhận không trùng khớp!"
            return
        }


        const data: any = {
            oldPassword: oldPassword,
            newPassword: password,
        }

        inforStore.isLoadingBt = true
        const res = await accountService.changePass(data,getCookie('token'));
        inforStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            toastUtil.success('Đổi mật khẩu thành công');
            inforStore.clearForm()
        } else {
            toastUtil.error(res.body.message ?? 'Đổi mật khẩu thất bại', 2);
        }
    }

    async logOutAll(token: any) {
        const result = await accountService.logOutAll(token);
        if (result.status === HttpStatusCode.OK) {
            StorageService.removeToken();
            StorageService.removeRefreshToken()
            console.log(process.env.REACT_APP_DOMAIN_SSO)
            window.location.href = `https://accounts.omcheck.com/login?redirectTo=${new URLSearchParams(window.location.search).get('redirectTo')}&username=${new URLSearchParams(window.location.search).get('username')}&service=${new URLSearchParams(window.location.search).get('service')}`
        }
    }
}

export const inforStore = new InforAccountStore()